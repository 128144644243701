import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { Button, Card, Pagination, Field, CellGroup, Form, Picker, Popup } from 'vant';
import 'vant/lib/index.css';



createApp(App).use(router)
    .use(Button)
    .use(Card)
    .use(Popup)
    .use(Pagination)
    .use(Field).use(Form)
    .use(CellGroup).use(Picker)
    .mount('#app')