<template>
    <div class="hello">
        <div style="padding: 10px 15px;" class="lead">
            <h3 style="font-family: 'IBM Plex Sans Thai', sans-serif!important;">{{msg}}</h3>
            <p class="sub-title">หากปุ่มไหนกดไม่ได้ แสดงว่าลิ้งที่นั่นไม่มีนะครับ ผมจะพยายามใส่ให้เยอะที่สุดนะครับหากสินค้าไหนไม่มีสอบถามได้เลยครับ สินค้าทั้งหมด {{count}} ชิ้น</p>
        </div>
       
     

        <van-card v-for="(item,i) in data" :key="i" currency="฿" :tag="item.is_hot == true ? 'HOT': ''" 
        :title="item.name"  
        :desc="item.desc"
        :price="item.price" 
        :thumb="item.image">
            <template #num>
                <van-button style="margin-right: 12px;"  :class="item.market+'-button' "  :url="item.url">
                    {{item.market == "other" ?'Other' :''}}
                </van-button>
            </template>
        </van-card>



    </div>
</template>
<script>



export default {
    name: 'HelloWorld',
    props: {
        msg: String,
        data: null,
         count:null
    },
    data() {
        return {
            name: '',
            capital: '',
          
          
        }
    },
    created() {

    },
    methods: {
        async getAllItems() {
            const querySnap = await getDocs(query(collection(db, 'items'), orderBy('createAt', 'desc'), limit(5) ));
            querySnap.forEach((doc) => {
                this.items.push(doc.data())
            })

            this.count = this.items.length
        }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.van-card__footer .van-button {
    margin-right: 25px;
}

.van-card {
    border-bottom: 1px solid #e5e5e594;
    border-top: 1px solid #e5e5e5bd;
}

.lazada-button {
    color: #ffffff;
    background: url(../assets/laz-logo.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #0f146c;
}

.shopee-button {
    color: #ffffff;
    background: url(../assets/shopee-logo.png);
    background-size: contain;
    background-repeat: no-repeat;
}

.van-button {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    height: 20px;
    margin: 0;
    padding: 0;
    font-size: 8pt;
    line-height: var(--van-button-default-line-height);
    text-align: center;
    border-radius: var(--van-button-radius);
    cursor: pointer;
    transition: opacity var(--van-duration-fast);
    -webkit-appearance: none;
    -webkit-font-smoothing: auto;
    width: 43px;
}

.van-tag {
    background: #fa1953;
}

.sub-title {
    padding: 0px 5px;
    margin-top: -5px;
    color: #546c84;

    line-height: 10pt;

    font-size: 8pt;
}

.hello {
    padding-bottom: 80px;
}

h1 {
    text-align: left;
}

h3 {
    margin: 0px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}
</style>